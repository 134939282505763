<template>
  <thux-table
    :instance="instance"
    :instance-name="instanceName"
    :order-by-dict="orderByDict"
    :goBack="goBack"
    :list="list"
    :fieldName="fieldName"
    :fields="fields"
    :fields-column="fieldsColumn"
    :select-all="selectAll"
    :selected-list="selectedList"
    :get-local-selected-all="getLocalSelectedAll"
    :local-selected-list="localSelectedList"
    @check-all="checkAll"
    @check-none="checkNone"
    @set-selected-row="setSelectedRow"
    :show-checks="showChecks"
    :showDetailButton="showDetailButton"
    @change-status="changeInstanceStatus"
    @sort-changed="orderTable"
    @remove-order-by="removeOrderFromTable"
    @open-detail-form="$emit('open-detail-form', $event)"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ADDRESS_SCOPE_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxTableMixin from '../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'

export default {
  name: 'AddressScopeTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  data () {
    return {
      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'name',
          label: this.$t('Name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'name'
        },
        {
          key: 'slug',
          label: this.$t('Slug'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'slug'
        },
        {
          key: 'is_invoice_address',
          label: this.$t('Invoice address'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          type: 'boolean',
          sortable: true,
          value: 'is_invoice_address'
        },
        {
          key: 'is_shipping_address',
          label: this.$t('Shipping address'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          type: 'boolean',
          sortable: true,
          value: 'is_shipping_address'
        },
        {
          key: 'is_registered_office',
          label: this.$t('Registered office'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          type: 'boolean',
          sortable: true,
          value: 'is_registered_office'
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'name', 'slug', 'is_invoice_address', 'is_shipping_address']
    }
  },
  computed: {
    ...mapGetters({
      selectAll: ADDRESS_SCOPE_TYPES.GENERIC.address.addressscope.LIST.GETTERS.selectAll,
      selectedList: ADDRESS_SCOPE_TYPES.GENERIC.address.addressscope.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return true
    },
    showDetailButton () {
      return hasPerm('address_addressscope_retrieve')
    }
  },
  methods: {
    ...mapMutations({
      setList: ADDRESS_SCOPE_TYPES.GENERIC.address.addressscope.LIST.MUTATIONS.setList,
      setSelectedList:
        ADDRESS_SCOPE_TYPES.GENERIC.address.addressscope.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ADDRESS_SCOPE_TYPES.GENERIC.address.addressscope.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...ADDRESS_SCOPE_TYPES.GENERIC.address.addressscope.LIST.ACTIONS
    })
  }
}
</script>
