<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ADDRESS_SCOPE_TYPES } from './store'
import { ROUTES as ADDRESS_SCOPE_ROUTES } from './router'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'
import { hasPerm } from '@/components/PermissionHelper'

import AddressScopeTable from './AddressScopeTable'
import AddressScopeCommonFilters from './AddressScopeCommonFilters'
import AddressScopeEdit from './AddressScopeEdit.vue'

export default {
  name: 'AddressScopeList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': AddressScopeTable,
    'common-filters': AddressScopeCommonFilters,
    'component-edit': AddressScopeEdit,
    'component-detail': AddressScopeEdit
  },
  data () {
    return {
      rolePerm: 'address_addressscope_list',
      actionEnablePermission: ['address_addressscope_enable'],
      actionDisablePermission: ['address_addressscope_disable'],
      ADDRESS_SCOPE_ROUTES,
      title: this.$t('Addresses scope'),
      searchFields: [
        {
          key: 'filter__name__icontains',
          placeholder: this.$t('Name'),
          type: 'text',
          col: 6
        },
        {
          key: 'filter__slug__icontains',
          placeholder: this.$t('Slug'),
          type: 'text',
          col: 6
        },
        {
          key: 'filter__is_invoice_address',
          placeholder: this.$t('Invoice address'),
          type: 'boolean',
          col: 6,
          options: [
            { value: 'True', text: this.$t('Yes') },
            { value: 'False', text: this.$t('No') }
          ]
        },
        {
          key: 'filter__is_shipping_address',
          placeholder: this.$t('Shipping address'),
          type: 'boolean',
          col: 6,
          options: [
            { value: 'True', text: this.$t('Yes') },
            { value: 'False', text: this.$t('No') }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...ADDRESS_SCOPE_TYPES.GENERIC.address.addressscope.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.ADDRESS_SCOPE_ROUTES.ADDRESS_SCOPE_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('address_addressscope_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: ADDRESS_SCOPE_TYPES.GENERIC.address.addressscope.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ADDRESS_SCOPE_TYPES.GENERIC.address.addressscope.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...ADDRESS_SCOPE_TYPES.GENERIC.address.addressscope.LIST.ACTIONS
    })
  }
}
</script>
